const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

interface ImageParams {
  filename: string;
  q?: number;
  w?: number;
  h?: number;
}

const getCafeCoverURL = ({ filename, q = 80, w, h }: ImageParams): string => {
  if (!filename) {
    return '';
  }

  const url = new URL(`${API_BASE_URL}/image/${filename}`);

  if (q && q >= 1 && q <= 100) {
    url.searchParams.append('quality', q.toString());
  }

  if (w && w > 0) {
    url.searchParams.append('width', w.toString());
  }

  if (h && h > 0) {
    url.searchParams.append('height', h.toString());
  }

  return url.toString();
};

const getCafeCoverThumbnailURL = (filename: string, size: number = 100): string => {
  return getCafeCoverURL({ filename, w: size, h: size, q: 60 });
};


export {
  getCafeCoverURL,
  getCafeCoverThumbnailURL
};

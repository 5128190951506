import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CafeDetail } from '../types/cafe.ts';
import { selectCurrentToken } from '../redux/slices/authSlice.ts';
import { RootState } from '../redux/store.ts';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

interface CafeDetailResponse {
  cafe: CafeDetail;
}

interface CafeListResponse {
  cafes: CafeDetail[];
  cafeCount: number;
  code: number;
  totalPages: number;
  count: number;
}

interface LoginParams {
  username: string;
  password: string;
}

interface LoginResponse {
  username: string;
  token: string;
}

interface ImageResponse {
  images: string[];
}

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, Partial<LoginParams>>({
      query: ({ username, password }) => ({
        url: `/user/login?`,
        method: 'POST',
        body: { username, password },
      }),
      transformResponse: (response: { data; }, meta, arg) => response.data,
    }),
    getDistrictCount: builder.query<{ district: string, count: number; }[], void>({
      query: () => '/count/district',
    }),
    getCafes: builder.query<CafeListResponse,
      {
        page: number;
        limit: number;
        district: string;
        power_outlets: string;
        shopName: string;
        restroom: string;
      }>({
        query: ({ page, limit, district, power_outlets, shopName, restroom }) => `/query?page=${page}&limit=${limit}&district=${district}&power_outlets=${power_outlets}&shopName=${shopName}&restroom=${restroom}`,
      }),
    getCafeDetail: builder.query<CafeDetailResponse, string>({
      query: (id: string) => `/detail/${id}`,
    }),
    addCafe: builder.mutation<CafeDetail, Partial<CafeDetail>>({
      query: (body) => {
        // const token = selectCurrentToken((state: RootState) => state);
        const token = localStorage.getItem('token');
        return {
          url: `/add?token=${token}`,
          method: 'POST',
          body,
        };
      },
    }),
    deleteCafe: builder.mutation<string, Partial<string>>({
      query: (id) => {
        const token = localStorage.getItem('token');
        return {
          url: `/delete?id=${id}&token=${token}`,
          method: 'POST',
        };
      },
    }),
    editCafe: builder.mutation<string, { id: string; body: Partial<string>; }>({
      query: ({ id, body }) => {
        const token = localStorage.getItem('token');
        return {
          url: `/edit?id=${id}&token=${token}`,
          method: 'POST',
          body,
        };
      },
    }),
    uploadImages: builder.mutation<ImageResponse, { selectedFileList: FileList, id: string; }>({
      query: ({ selectedFileList, id }) => {
        const token = localStorage.getItem('token');

        const formData = new FormData();
        Array.from(selectedFileList).forEach((file: any) => {
          formData.append('files', file);
        });

        return {
          url: `/upload?cafeId=${id}&token=${token}`,
          method: 'POST',
          body: formData,
        };
      }
    })
  }),
});

export const {
  useGetDistrictCountQuery,
  useGetCafesQuery,
  useGetCafeDetailQuery,
  useLoginMutation,
  useAddCafeMutation,
  useDeleteCafeMutation,
  useEditCafeMutation,
  useUploadImagesMutation
} = api;
// ProtectedRoute.js
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { selectCurrentToken } from '../redux/slices/authSlice.ts';
import Layout from './Layout.tsx';

const ProtectedRoute: React.FC = () => {
  const token = useSelector(selectCurrentToken);

  if (!token) {
    return <Navigate to="/backend/login" replace />;
  }

  return <Layout />;
};

export default ProtectedRoute;
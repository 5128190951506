import React, { useState, useCallback } from 'react';

import {
  Typography,
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from '@material-tailwind/react';

import { DeletingCafe } from '../types/cafe';
import { useDeleteCafeMutation } from '../services/query.ts';

interface DeleteDialogProps {
  isOpen: boolean;
  deletingCafe: {
    shopName: string;
    _id: string;
  };
  onToggleDialog: (isOpen: boolean) => void;
  onDeleteSuccess: () => void;
}

const DeleteDialog: React.FC<DeleteDialogProps> = ({ isOpen, deletingCafe, onToggleDialog, onDeleteSuccess }) => {

  const [deleteCafe, { isLoading, error }] = useDeleteCafeMutation();

  const handleDeleteDialog = useCallback((cafe: DeletingCafe) => {
    onToggleDialog(!isOpen);
  }, []);

  const confirmDeleteCafe = useCallback(async () => {
    try {
      await deleteCafe(deletingCafe._id).unwrap();
      onDeleteSuccess();
    } catch (error) {
      console.log(error);
    }
  }, [deletingCafe._id]);

  return (
    <Dialog open={isOpen} handler={handleDeleteDialog}>
      <DialogHeader>
        <Typography variant="h5" color="blue-gray">
          ⚠️ 注意：正在删除店铺信息
        </Typography>
      </DialogHeader>
      <DialogBody divider className="grid gap-4 place-items-center">
        <Typography className="font-bold text-center">
          【{deletingCafe?.shopName}】 店铺信息将被永久删除，是否继续？
        </Typography>
      </DialogBody>
      <DialogFooter className="space-x-2">
        <Button variant="text" color="blue-gray" onClick={() => onToggleDialog(!isOpen)}>
          取消
        </Button>
        {isLoading ? (
          <Button variant="gradient" disabled>删除中...</Button>
        ) :
          <Button variant="gradient" onClick={confirmDeleteCafe}>
            {error ? '删除失败 重试' : '确认删除'}
          </Button>}
      </DialogFooter>
    </Dialog>
  );
};

export default DeleteDialog;
import { Select, Option, Typography, Card, CardBody, Input, Textarea, Button } from '@material-tailwind/react';
import React, { useState, useCallback, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { PhotoIcon } from '@heroicons/react/24/solid';
import { CafeDetail } from '../types/cafe';

import { getCafeCoverURL } from '../utils/images.ts';

import ImageGallery from './ImageGallery.tsx';
import MapContainer from '../utils/mapContainer.tsx';

import useCoverSubmit from '../hooks/useCoverSubmit.tsx';
import useMapCoordinates from '../hooks/useMapCoordinates.tsx';

interface CafeFormProps {
  initialData?: Partial<CafeDetail>;
  onSubmit: (data: CafeDetail) => void;
  isLoading?: boolean;
  error?: any;
}

const CafeForm: React.FC<CafeFormProps> = ({ initialData, onSubmit, isLoading, error }) => {
  const { control, register, handleSubmit, watch, setValue, formState: { errors } } = useForm<CafeDetail>({
    defaultValues: initialData
  });

  const [selectedFileList, setSelectedFileList] = useState<FileList | null>(null);

  const coordinates = watch('coordinates');
  const cafeId = watch('_id') as string;

  const {
    covers,
    setCovers,
    activeFilename,
    setActive,
    onCoverSubmit
  } = useCoverSubmit({ initialData });

  const { getCoordinates, mapLoading } = useMapCoordinates(watch, setValue);

  const changeMultipleFiles = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFileList(e.target.files);
    }
  }, []);

  useEffect(() => {
    if (selectedFileList) {
      onCoverSubmit(selectedFileList, cafeId);
    }
  }, [selectedFileList]);

  const handleFormSubmit = (data: CafeDetail) => {
    onSubmit({
      ...data,
      covers,
    });
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className='grid grid-cols-2 mb-100'>
        <Card
          className="w-auto mt-3 mr-3">
          <CardBody>
            <div className='mb-2'>
              <div>
                <div className="flex flex-col gap-4">
                  <Typography variant="h6" color="blue-gray" className="-mb-3">
                    店名
                  </Typography>
                  <Input
                    {...register('shopName')}
                    size="md"
                    containerProps={{ className: '!min-w-[100px]' }}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                  <Typography variant="h6" color="blue-gray" className="-mb-3">
                    地址
                  </Typography>
                  <Input
                    {...register('address')}
                    size="md"
                    containerProps={{ className: '!min-w-[100px]' }}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900 "
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }} />
                  <Typography variant="h6" color="blue-gray" className="-mb-3">
                    行政区域 / 地区
                  </Typography>
                  <div className='grid items-center grid-cols-2 gap-y-3 gap-x-2'>
                    <Input
                      {...register('district')}
                      size="md"
                      containerProps={{ className: '!min-w-[100px]' }}
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900 col-span-1 min-w-[100px]"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                    <Input
                      {...register('region')}
                      size="md"
                      containerProps={{ className: '!min-w-[100px]' }}
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900 col-span-1"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                  <Typography variant="h6" color="blue-gray" className="-mb-3">
                    地铁站
                  </Typography>
                  <Input
                    {...register('nearby_subway')}
                    size="md"
                    containerProps={{ className: '!min-w-[100px]' }}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }} />
                  <Typography variant="h6" color="blue-gray" className="mr-3 -mb-3">
                    经纬度
                    <Button size="sm" onClick={getCoordinates} className='mb-2 ml-3' disabled={mapLoading}>获取经纬度</Button>

                    <div className='flex text-sm'>
                      <span>({coordinates?.latitude},{coordinates?.longitude})</span>
                    </div>
                  </Typography>
                  <div className=''>
                    <MapContainer location={coordinates} />
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>

        <Card className="w-auto mt-3 mr-3">
          <CardBody>
            <div className='mb-2 w-80 sm:w-96'>
              <div>
                <div className="flex flex-col gap-4">
                  <Typography variant="h6" color="blue-gray" className="-mb-3">
                    插座情况
                  </Typography>
                  <Controller
                    name="power_outlets"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        onChange={(val) => field.onChange(val)}
                      >
                        <Option value='无'>无</Option>
                        <Option value='有少量（1-4）'>有少量（1-4）</Option>
                        <Option value='靠墙位置有'>靠墙位置有</Option>
                        <Option value='每个位置都有'>每个位置都有</Option>
                      </Select>
                    )}
                    defaultValue="有少量（1-4）"
                  />
                  <Typography variant="h6" color="blue-gray" className="-mb-3">
                    卫生间情况
                  </Typography>

                  <Controller
                    name="restroom"
                    control={control}
                    defaultValue="店内"
                    render={({ field }) => (
                      <Select
                        {...field}
                        onChange={(val) => field.onChange(val)}
                      >
                        <Option value='无'>无</Option>
                        <Option value='店内'>店内</Option>
                        <Option value='大楼、商场、园区内'>大楼、商场、园区内</Option>
                      </Select>
                    )}
                  />

                  <Typography variant="h6" color="blue-gray" className="-mb-3">
                    是否连锁店
                  </Typography>

                  <Controller
                    name="chain_store"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        onChange={(val) => field.onChange(val)}
                      >
                        <Option value='不是' defaultChecked>不是</Option>
                        <Option value='星巴克'>星巴克</Option>
                        <Option value='皮爷咖啡'>皮爷咖啡</Option>
                        <Option value='Tims 咖啡'>Tims 咖啡</Option>
                        <Option value='Wagas'>Wagas</Option>
                        <Option value='MStand'>MStand</Option>
                        <Option value='Seesaw'>Seesaw</Option>
                      </Select>
                    )}
                    defaultValue="不是"
                  />

                  <Typography variant="h6" color="blue-gray" className="-mb-3">
                    营业时间
                  </Typography>
                  <Input
                    size="md"
                    containerProps={{ className: '!min-w-[100px]' }}
                    {...register('business_hours')}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />

                  <Typography variant="h6" color="blue-gray" className="-mb-3">
                    店铺链接
                  </Typography>
                  <Input
                    {...register('dianping_link')}
                    size="md"
                    containerProps={{ className: '!min-w-[100px]' }}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />

                  <Typography variant="h6" color="blue-gray" className="-mb-3">
                    小红书链接
                  </Typography>
                  <Input
                    {...register('redbook_link')}
                    size="md"
                    containerProps={{ className: '!min-w-[100px]' }}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />

                  <Typography variant="h6" color="blue-gray" className="-mb-3">
                    描述
                  </Typography>
                  <Textarea label="Message" {...register('description')} />
                </div>
              </div>
            </div>
          </CardBody>


        </Card>
      </div>

      <Card className='bottom-0 flex w-full p-6 mt-3'>
        <div className='grid grid-cols-3'>
          <div className="flex justify-center px-6 py-10 mr-2 border border-dashed rounded-lg border-gray-900/25">
            <div className="text-center">
              <PhotoIcon aria-hidden="true" className="w-12 h-12 mx-auto text-gray-300" />
              <div className="flex mt-4 text-sm leading-6 text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative font-semibold text-indigo-600 bg-white rounded-md cursor-pointer focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                >
                  <span>Upload a file</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="sr-only"
                    onChange={changeMultipleFiles}
                    multiple
                  />
                </label>
                {selectedFileList && <p className="pl-1">{selectedFileList.length} files selected</p>}
              </div>
              <p className="text-xs leading-5 text-gray-600">PNG, JPG up to 10MB</p>
            </div>
          </div>

          <div className="grid col-span-2 gap-3">
            <div>
              {covers.length > 0 && (
                <img
                  className="w-fit max-w-full rounded-lg object-cover object-left h-[480px]"
                  src={getCafeCoverURL({ filename: activeFilename, q: 80, w: 800 })}
                  alt=""
                />
              )}
            </div>
            <ImageGallery
              covers={covers}
              onChange={(newCovers) => setCovers(newCovers)}
              onHandleClick={(newCovers) => setActive(newCovers)} />
          </div>
        </div>
      </Card>

      <Card className='bottom-0 flex w-full p-6 mt-3'>
        <button type="submit" className="w-40 px-4 py-2 text-white bg-blue-500 rounded">
          {isLoading ? '保存中...' : error ? '保存失败' : initialData ? '保存' : '创建'}
        </button>
      </Card>
    </form>
  );
};

export default CafeForm;
import React, { useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { PlusCircleIcon, MagnifyingGlassIcon, TrashIcon } from '@heroicons/react/24/outline';
import { PencilIcon } from '@heroicons/react/24/solid';
import { formatDate } from '../utils/date.ts';
import { useGetCafesQuery } from '../services/query.ts';
import { getCafeCoverThumbnailURL } from '../utils/images.ts';

import DeleteDialog from '../components/DeleteDialog.tsx';
import { districtList } from '../constants/index.ts';

import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  Select,
  Option,
  CardFooter,
  Avatar,
  IconButton,
  Input,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Chip,
} from '@material-tailwind/react';

import { CafeDetail, DeletingCafe } from '../types/cafe';

const ListPage: React.FC = () => {
  const [page, setPage] = useState(1);
  const limit = 10;

  const [cafes, setCafes] = useState<CafeDetail[]>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletingCafe, setDeletingCafe] = useState<DeletingCafe>({ shopName: '', _id: '' });
  const [queryValue, setQueryValue] = useState({
    district: '',
    power_outlets: '',
    restroom: '',
    shopName: ''
  });
  const setQuery = useCallback((val, key) => {
    setQueryValue(preState => ({
      ...preState,
      [key]: val,
    }));
    setPage(1);
  }, []);

  const { data, error, isLoading } = useGetCafesQuery({ page, limit, ...queryValue });

  useEffect(() => {
    setCafes(data?.cafes || []);
  }, [data, queryValue]);

  const count = data?.count || 0;

  const handleDeleteDialog = useCallback((cafe: DeletingCafe) => {
    setDeletingCafe(cafe);
    setDeleteDialogOpen(preState => !preState);
  }, []);

  const confirmDeleteCafe = useCallback(async () => {
    try {
      setDeleteDialogOpen(false);
      setCafes((prevCafes) => prevCafes.filter((cafe) => cafe._id !== deletingCafe._id));
    } catch (error) {
      console.log(error);
    }
  }, [deletingCafe._id]);

  const TABLE_HEAD = ['更新时间', '基本信息', '位置', '卫生间', '插座', ''];

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      <div className="flex flex-col gap-12 mb-4">
        <Card className='mt-12'>
          <CardHeader variant="gradient" color="gray" className="p-6 mb-4">
            <Typography variant="h6" color="white">
              咖啡店列表
            </Typography>
          </CardHeader>
          <CardBody className="min-h-screen px-0 pt-0 pb-2 overflow-x-scroll">
            <div className="flex flex-col justify-between gap-8 p-6 mb-4 md:flex-row md:items-center">
              <div className='flex'>
                <Select label="行政区" containerProps={{ className: 'mr-2 !min-w-[150px]' }}
                  value={queryValue.district}
                  onChange={(val) => setQuery(val, 'district')}>
                  <Option value='全部' defaultChecked>全部</Option>
                  <Option value='黄浦区'>黄浦区</Option>
                  <Option value='静安区'>静安区</Option>
                  <Option value='长宁区'>长宁区</Option>
                  <Option value='徐汇区'>徐汇区</Option>
                  <Option value='浦东新区'>浦东新区</Option>
                  <Option value='虹口区'>虹口区</Option>
                  <Option value='杨浦区'>杨浦区</Option>
                  <Option value='杨浦区'>普陀区</Option>
                  <Option value='宝山区'>宝山区</Option>
                </Select>
                <Select label="插座" containerProps={{ className: 'mr-2 !min-w-[150px]' }}
                  value={queryValue.power_outlets}
                  onChange={(val) => setQuery(val, 'power_outlets')}>
                  <Option value='全部' defaultChecked>全部</Option>
                  <Option value='无'>无</Option>
                  <Option value='有少量（1-4）'>有少量（1-4）</Option>
                  <Option value='靠墙位置有'>靠墙位置有</Option>
                  <Option value='每个位置都有'>每个位置都有</Option>
                </Select>
                <Select label="卫生间" containerProps={{ className: 'mr-2 !min-w-[200px]' }}
                  value={queryValue.restroom}
                  onChange={(val) => setQuery(val, 'restroom')}>
                  <Option value='全部' defaultChecked>全部</Option>
                  <Option value='无'>无</Option>
                  <Option value='店内'>店内</Option>
                  <Option value='大楼、商场、园区内'>大楼、商场、园区内</Option>
                </Select>
              </div>
              <div className="flex w-full gap-2 shrink-0 md:w-max">
                <div className="w-full md:w-72">
                  {/* TODO: debounce */}
                  <Input
                    label="店名搜索"
                    value={queryValue.shopName}
                    onChange={(e) => setQuery(e.target.value, 'shopName')}
                    icon={<MagnifyingGlassIcon className="w-5 h-5" />} />
                </div>
                <Link to='/backend/add' className='flex md:w-50'>
                  <Button className="flex items-center gap-3" size="sm">
                    <PlusCircleIcon strokeWidth={2} className="w-4 h-4 mr-2" /> 新增
                  </Button>
                </Link>

              </div>
            </div>

            <table className="w-full min-w-[640px] table-auto">
              <thead>
                <tr>
                  {TABLE_HEAD.map((el) => (
                    <th
                      key={el}
                      className="px-5 py-3 text-left border-b border-blue-gray-50"
                    >
                      <Typography
                        variant="small"
                        className="text-[11px] font-bold uppercase text-blue-gray-400"
                      >
                        {el}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {cafes.map(
                  ({
                    covers,
                    updatedAt,
                    _id,
                    shopName,
                    district,
                    region,
                    address,
                    power_outlets,
                    restroom,
                  }, key) => {
                    const className = `py-3 px-5 ${key === cafes.length - 1
                      ? ""
                      : "border-b border-blue-gray-50"
                      }`;

                    return (
                      <tr key={_id}>

                        <td className={className}>
                          <Typography className="text-xs font-semibold text-blue-gray-600">
                            {formatDate(updatedAt)}
                          </Typography>
                        </td>
                        <td className={className}>
                          <div className="flex items-center gap-4">
                            {covers.length > 0 && covers[0] &&
                              <Avatar src={getCafeCoverThumbnailURL(covers[0], 200)} alt={shopName} size="sm" variant="rounded" />}
                            <div>
                              <Link to={`/backend/detail/${_id}`}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-semibold"
                                >
                                  {shopName}
                                </Typography>
                                <Typography className="text-xs font-normal text-blue-gray-500">
                                  {address}
                                </Typography>
                              </Link>
                            </div>
                          </div>
                        </td>

                        <td className={className}>
                          <Typography className="text-xs font-semibold text-blue-gray-600">
                            {district}
                          </Typography>
                          <Typography className="text-xs font-normal text-blue-gray-500">
                            {region}
                          </Typography>
                        </td>
                        <td className={className}>
                          <Chip
                            variant="gradient"
                            color={restroom === '无' ? "red" : "blue"}
                            value={restroom}
                            className="py-0.5 px-2 text-[11px] font-medium w-fit"
                          />
                        </td>
                        <td className={className}>
                          <Chip
                            variant="gradient"
                            color={power_outlets === '无' ? "red" : "blue"}
                            value={power_outlets}
                            className="py-0.5 px-2 text-[11px] font-medium w-fit"
                          />
                        </td>
                        <td className={className}>
                          {/* edit */}
                          <Link to={`/backend/edit/${_id}`}>
                            <IconButton variant="text">
                              <PencilIcon className="w-4 h-4 mr-2" />
                            </IconButton></Link>
                          {/* delete */}
                          <IconButton variant="text" onClick={(_) => handleDeleteDialog({ shopName, _id })}>
                            <TrashIcon className="w-4 h-4" />
                          </IconButton>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>

            <CardFooter className="flex items-center self-center justify-center p-4 border-t border-blue-gray-50">
              <div className="flex items-center gap-2">
                {
                  Array.from({ length: Math.ceil(count / 10) }, (v, i) => i + 1).map((item) => (
                    <IconButton key={item}
                      variant={item === page ? 'filled' : 'text'}
                      size="sm"
                      onClick={() => setPage(item)}>
                      {item}
                    </IconButton>
                  ))
                }
              </div>
              <div className='text-[11px] font-bold ml-2'>Total Count: {count}</div>
            </CardFooter>
          </CardBody>
        </Card>
      </div>

      {/* delete dialog */}
      <DeleteDialog
        isOpen={deleteDialogOpen}
        deletingCafe={deletingCafe}
        onToggleDialog={() => setDeleteDialogOpen(!deleteDialogOpen)}
        onDeleteSuccess={() => confirmDeleteCafe()} />
    </>
  );
};

export default ListPage;




import { Card, CardBody } from '@material-tailwind/react';
import React, { useEffect, useState } from 'react';
import CardWithList from '../components/CardWithList.tsx';
import { useGetDistrictCountQuery } from '../services/query.ts';

const Dashboard: React.FC = () => {
  const { data, error, isLoading } = useGetDistrictCountQuery();

  return (
    <div>
      <CardWithList counts={data?.counts} />
      {/* <Card className="mt-4 border shadow-sm w-96 border-blue-gray-100">
        <CardBody>
          22
        </CardBody>
      </Card> */}
    </div>
  );
};

export default Dashboard;




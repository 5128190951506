/**
 * 将ISO日期字符串转换为自定义格式 YYYY-mm-DD HH:MM
 * @param isoString - ISO日期字符串
 * @returns 格式化后的日期字符串
 */
const formatDate = (isoString: string): string => {
  if (!isoString) return '--';
  const date = new Date(isoString);

  const pad = (num: number): string => (num < 10 ? '0' + num : num.toString());

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // 月份从0开始，所以要加1
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export { formatDate };
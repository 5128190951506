import React from 'react';
import { useNavigate } from 'react-router-dom';
import CafeForm from '../components/CafeForm.tsx';
import { CafeDetail } from '../types/cafe.ts';
import { useAddCafeMutation } from '../services/query.ts';

const AddPage: React.FC = () => {
  const navigate = useNavigate();
  const [addCafe, { isLoading, error }] = useAddCafeMutation();

  const handleSubmit = async (data: CafeDetail) => {
    try {
      await addCafe(data).unwrap();
      setTimeout(() => {
        navigate('/backend/list');
      }, 1500);
    } catch (error) {
      console.error('Error submitting data', error);
    }
  };

  return <CafeForm onSubmit={handleSubmit} isLoading={isLoading} error={error} />;
};

export default AddPage;
import {
  Typography,
  Card,
  CardBody,
  Carousel,
  Button,
  Breadcrumbs,
  Avatar
} from '@material-tailwind/react';
import React, { useState, useCallback } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { getCafeCoverURL } from '../utils/images.ts';
import MapContainer from '../utils/mapContainer.tsx';
import { useGetCafeDetailQuery } from '../services/query.ts';
import { DeletingCafe } from '../types/cafe';

import DeleteDialog from '../components/DeleteDialog.tsx';

import RedbookImg from '../images/redbook1.svg';
import DianpingImg from '../images/dazhongdianping.svg';

const DetailPage: React.FC = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const { data, error, isLoading } = useGetCafeDetailQuery(id as string);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletingCafe, setDeletingCafe] = useState<DeletingCafe>({ shopName: '', _id: '' });

  const handleDeleteDialog = useCallback((cafe: DeletingCafe) => {
    setDeletingCafe(cafe);
    setDeleteDialogOpen(preState => !preState);
  }, []);

  const confirmDeleteCafe = useCallback(async () => {
    try {
      setDeleteDialogOpen(false);
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  }, [deletingCafe._id]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const {
    _id,
    covers = [],
    shopName,
    district,
    region,
    address,
    business_hours,
    nearby_subway,
    restroom,
    power_outlets,
    coordinates,
    description,
    redbook_link,
    dianping_link
  } = data?.cafe || {};

  return (
    <div>
      <div className="relative w-full mt-8 overflow-hidden bg-center bg-cover h-80 rounded-xl">
        {covers.length > 0 && (
          <img src={getCafeCoverURL({ filename: covers[0], w: 1200, q: 80 })}
            className="absolute inset-0 object-cover object-center w-full h-full bg-gray-900/75" alt="" />
        )}
      </div>
      <Card className="mx-3 mb-6 -mt-16 border lg:mx-4 border-blue-gray-100">
        <CardBody className="p-4">
          <div className="flex flex-wrap items-center justify-between gap-6 mb-10">
            <div className="flex items-center gap-6">
              <div>
                <Typography variant="h5" color="blue-gray" className="mb-1">
                  {shopName}
                </Typography>
                <Typography
                  variant="small"
                  className="font-normal text-blue-gray-600"
                >
                  {district} /  {region} / {address} ｜ {business_hours}
                </Typography>
              </div>
            </div>
            <div className='flex'>
              <Link to={`/backend/edit/${_id}`}>
                <Button variant="text" className="flex items-center gap-2">
                  编辑<PencilSquareIcon strokeWidth={2} className="w-4 h-4" />
                </Button>
              </Link>
              <Button onClick={(_) => handleDeleteDialog({ shopName, _id })} variant="text" className="flex items-center gap-2">
                删除<TrashIcon strokeWidth={2} className="w-4 h-4" />
              </Button>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-12 px-4 mb-12">
            <div>
              <Typography variant="h6" color="blue-gray" className="mb-3">
                基本信息
              </Typography>
              <div className="flex flex-col gap-1">
                <Typography className="block mb-2 text-sm font-semibold uppercase text-blue-gray-500">
                  附近地铁站：{nearby_subway}
                </Typography>
                <Typography className="block mb-2 text-sm font-semibold uppercase text-blue-gray-500">
                  卫生间：{restroom}
                </Typography>
                <Typography className="block mb-2 text-sm font-semibold uppercase text-blue-gray-500">
                  插座：{power_outlets}
                </Typography>
                <Typography className="block mb-2 text-sm font-semibold uppercase text-blue-gray-500">
                  Wi-Fi：---
                </Typography>
                <Typography className="block mb-2 text-sm font-semibold uppercase text-blue-gray-500">
                  座位数：---
                </Typography>
              </div>
            </div>
            <div>
              <Typography variant="h6" color="blue-gray" className="mb-3">
                相关链接
              </Typography>
              <ul className="flex flex-col gap-6">
                <div className="flex items-center justify-between gap-4">
                  <a target='_blank' href={redbook_link} className="flex items-center gap-4 ">
                    <Avatar
                      src={RedbookImg}
                      alt="小红书"
                      variant="rounded"
                      className="shadow-l"
                    />
                    <div>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-semibold"
                      >
                        点击查看相关小红书笔记
                      </Typography>
                      {/* <Typography className="text-xs font-normal text-blue-gray-400">
                      </Typography> */}
                    </div>
                  </a>
                </div>

                <div className="flex items-center justify-between gap-4">
                  <a target='_blank' href={dianping_link} className="flex items-center gap-4">
                    <Avatar
                      src={DianpingImg}
                      alt="小红书"
                      variant="rounded"
                      className="shadow-l"
                    />
                    <div>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-semibold"
                      >
                        点击前往 大众点评 店铺详情页
                      </Typography>
                      {/* <Typography className="text-xs font-normal text-blue-gray-400">
                        2222 人已经探店
                      </Typography> */}
                    </div>
                  </a>
                </div>
              </ul>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-12 px-4 pb-4 mb-12">
            <div>
              <Typography variant="h6" color="blue-gray" className="mb-2">
                店内环境
              </Typography>
              <Typography
                variant="small"
                className="font-normal text-blue-gray-500"
              >
                <Carousel className="w-auto rounded-xl">
                  {covers.length > 0 && covers.map((cover, index) => (
                    <img
                      key={index}
                      src={getCafeCoverURL({ filename: cover })}
                      alt={`image ${index + 1}`}
                      className="object-cover w-full h-full h-min-80 rounded-xl"
                    />
                  ))}
                </Carousel>
              </Typography>

            </div>
            <div>
              <Typography variant="h6" color="blue-gray" className="mb-3">
                探店评价
              </Typography>
              <p className='block mb-3 font-sans text-sm antialiased font-normal leading-normal text-blue-gray-500'>
                {description}
              </p>
              <Typography variant="h6" color="blue-gray" className="mb-3">
                地图
              </Typography>
              <MapContainer location={coordinates} />
            </div>
          </div>
        </CardBody>
      </Card>


      {/* delete dialog */}
      <DeleteDialog
        isOpen={deleteDialogOpen}
        deletingCafe={deletingCafe}
        onToggleDialog={() => setDeleteDialogOpen(!deleteDialogOpen)}
        onDeleteSuccess={() => confirmDeleteCafe()} />
    </div>
  );
};

export default DetailPage;

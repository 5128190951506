import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useEditCafeMutation, useGetCafeDetailQuery } from '../services/query.ts';
import CafeForm from '../components/CafeForm.tsx';

const EditPage: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [editCafe, { isLoading, error }] = useEditCafeMutation();
  const { data, isLoading: isGetCafeLoading, error: getCafeError, } = useGetCafeDetailQuery(id as string);

  const handleSubmit = async (data) => {
    try {
      editCafe({ id: id as string, body: data });
      navigate('/backend/list');
    } catch (error) {
      console.error('Error submitting data', error);
    }
  };

  if (isGetCafeLoading) {
    return <div>Loading...</div>;
  }

  return <CafeForm initialData={data?.cafe} onSubmit={handleSubmit} />;
};

export default EditPage;
import { Card, CardBody, CardProps, Typography } from '@material-tailwind/react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

type CardWithListProps = {
  counts: { _id: string; count: number; }[];
};

const CardWithList: React.FC<CardWithListProps> = ({ counts }) => {
  return (
    <Card className="mt-4 border shadow-sm w-96 border-blue-gray-100">
      <CardBody>
        <div className="flex items-center justify-between mb-4">
          <Typography variant="h5" color="blue-gray" className="">
            行政区咖啡店数量
          </Typography>
          <Link to="/backend/list">
            <Typography
              as="a"
              href="#"
              variant="small"
              color="blue"
              className="font-bold"
            >
              View all
            </Typography>
          </Link>
        </div>
        <div className="divide-y divide-gray-200">
          {(counts || []).map(({ _id, count }, index) => (
            <div
              key={index}
              className="flex items-center justify-between pt-3 pb-3 last:pb-0"
            >
              <div className="flex items-center gap-x-3">
                <div>
                  <Typography color="blue-gray" variant="h6">
                    {_id}
                  </Typography>
                </div>
              </div>
              <Typography color="blue-gray" variant="h6">
                {count}
              </Typography>
            </div>
          ))}
        </div>
      </CardBody>
    </Card >
  );
};

export default CardWithList;
import { useState, useCallback } from 'react';
import { UseFormWatch, UseFormSetValue } from 'react-hook-form';

interface Coordinates {
  latitude: string;
  longitude: string;
}

const AMAP_KEY = process.env.REACT_APP_AMAP_KEY; // 确保在环境变量中设置了 AMAP_KEY

const useMapCoordinates = (
  watch: UseFormWatch<any>,
  setValue: UseFormSetValue<any>
) => {
  const [mapLoading, setLoading] = useState(false);
  const [error, setError] = useState<{ message: string; } | null>(null);

  const getCoordinates = useCallback(async () => {
    const address = watch('address');
    const district = watch('district');

    if (!address || !district) {
      setError({ message: '地址和区域都必须填写' });
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `//restapi.amap.com/v3/geocode/geo?key=${AMAP_KEY}&address=上海${district}${address}`
      );

      if (!response.ok) {
        throw new Error('网络请求失败');
      }

      const res = await response.json();

      if (res.status !== '1' || !res.geocodes || res.geocodes.length === 0) {
        throw new Error('无法获取坐标信息');
      }
      const [latitude, longitude] = res.geocodes[0].location.split(',');
      setValue('coordinates', { latitude, longitude });
    } catch (error) {
      console.error(error);
      setError({ message: error instanceof Error ? error.message : '获取坐标时发生错误' });
    } finally {
      setLoading(false);
    }
  }, [watch, setValue]);

  return { getCoordinates, mapLoading, error };
};

export default useMapCoordinates;
import { Outlet } from 'react-router-dom';
import Sidebar from './SideBar.tsx';
import React from 'react';

const Layout: React.FC = () => {
  return (
    <div className='flex h-100%'>
      <Sidebar />
      <div className="flex-1 p-4 bg-gray-100">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
import React, { useState, useRef, useEffect } from 'react';
import SortableList, { SortableItem } from 'react-easy-sort';
import { EllipsisHorizontalCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { getCafeCoverURL } from '../utils/images.ts';

interface CoversProps {
  covers: string[];
  onChange: (covers: string[]) => void;
  onHandleClick: (url: string) => () => void;
}

const ImageGallery: React.FC<CoversProps> = ({ covers, onChange, onHandleClick }) => {

  const [images, setImages] = useState<string[]>(covers);

  useEffect(() => {
    setImages(covers);
  }, [covers]);

  useEffect(() => {
    onChange(images);
  }, [images]);

  const containerRef = useRef(null);

  const handleClick = (e: React.MouseEvent, url: string) => {
    e.preventDefault();
    onHandleClick(url);
  };

  const handleDelete = (e: React.FormEvent, url: string) => {
    e.preventDefault();
    setImages(images.filter(img => img !== url));
  };

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    setImages((prevImages) => {
      const newImages = [...prevImages];
      const [removed] = newImages.splice(oldIndex, 1);

      newImages.splice(newIndex, 0, removed);
      onChange(newImages.map((item) => item));
      return newImages;
    });
  };

  return (
    <div ref={containerRef} >
      <SortableList onSortEnd={onSortEnd} className="list" draggedItemClassName="dragged">
        {images.map((item) => (
          <div key={item} className='relative inline-block mb-2 mr-2 h-fit w-28'>
            <img
              onClick={(e) => handleClick(e, item)}
              src={getCafeCoverURL({ filename: item, q: 30, w: 200 })}
              className="object-cover object-center max-w-full rounded-lg cursor-pointer h-fit"
              alt="gallery-image"
            />
            <button
              onClick={(e) => handleDelete(e, item)}
              className="absolute top-[-6px] right-[-6px] text-white transition-colors bg-gray-800 rounded-full hover:bg-gray-500 cursor-pointer"
            >
              <XCircleIcon className="w-6 h-6" />
            </button>
            <SortableItem key={item}>
              <EllipsisHorizontalCircleIcon className="w-6 h-6 cursor-move" />
            </SortableItem>
          </div>
        ))}
      </SortableList>
    </div >
  );
};

export default ImageGallery;
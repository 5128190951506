import { Card, CardBody } from '@material-tailwind/react';
import React, { useEffect, useState } from 'react';

const Map: React.FC = () => {
  return (
    <div>
      <Card className="mt-4 border shadow-sm w-96 border-blue-gray-100">
        <CardBody>
          Map
        </CardBody>
      </Card>
    </div>
  );
};

export default Map;




import { useEffect, useRef } from 'react';
import AMapLoader from "@amap/amap-jsapi-loader";
import React from 'react';

interface MapContainerProps {
  location: {
    latitude: string;
    longitude: string;
  };
}
declare global {
  interface Window {
    _AMapSecurityConfig: {
      securityJsCode: string;
    };
  }
}

const MapContainer: React.FC<MapContainerProps> = ({ location }) => {
  const mapRef = useRef<any>(null);
  const markerRef = useRef<any>(null);

  useEffect(() => {
    window._AMapSecurityConfig = {
      securityJsCode: process.env.REACT_APP_AMAP_SECURITY_CODE || '',
    };

    AMapLoader.load({
      key: process.env.REACT_APP_AMAP_JSAPI_KEY || '',
      version: '2.0',
      plugins: ['AMap.Scale'],
    })
      .then((AMap) => {
        if (!mapRef.current) {
          mapRef.current = new AMap.Map('container', {
            viewMode: '2D',
            zoom: 16,
            center: [location.latitude, location.longitude],
          });
        }
        if (!markerRef.current) {
          markerRef.current = new AMap.Marker({
            position: [location.latitude, location.longitude],
          });
          mapRef.current.add(markerRef.current);
        } else {
          markerRef.current.setPosition([location.latitude, location.longitude]);
        }
      }).catch((error) => {
        console.log(error);
      });

    return () => {
      if (mapRef.current) {
        mapRef.current.destroy();
        mapRef.current = null;
        markerRef.current = null;
      }
    };
  }, [location]);

  return (
    <div
      id="container"
      className='rounded-lg'
      style={{ height: "300px", width: "100%" }}
    ></div>
  );
};

export default MapContainer;
import './App.css';
import './input.css';
import React from 'react';

import { Provider } from 'react-redux';
import store from './redux/store.ts';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import Layout from './components/Layout.tsx';
import Sidebar from './components/SideBar.tsx';
import Dashboard from './pages/dashboard.tsx';
import List from './pages/list.tsx';
import Login from './pages/login.tsx';
import Detail from './pages/detail.tsx';
import Edit from './pages/edit.tsx';
import Add from './pages/add.tsx';
import Map from './pages/map.tsx';
import ProtectedRoute from './components/ProtectedRoute.tsx';

function App () {
  return (
    <Provider store={ store }>
      <Router>
        <div className="app">
          <Routes>
            <Route path="/backend/login" element={ <Login /> } />
            <Route path="/backend" element={ <ProtectedRoute /> }>
              <Route index element={ <Navigate to="dashboard" replace /> } />
              <Route path="dashboard" element={ <Dashboard /> } />
              <Route path="list" element={ <List /> } />
              <Route path="add" element={ <Add /> } />
              <Route path="map" element={ <Map /> } />
              <Route path="detail/:id" element={ <Detail /> } />
              <Route path="edit/:id" element={ <Edit /> } />
            </Route>
          </Routes>
        </div>
      </Router>
    </Provider >
  );
}

export default App;

import React, { useState } from 'react';

import { useUploadImagesMutation } from '../services/query.ts';

const useCoverSubmit = ({ initialData }) => {
  const [uploadImages] = useUploadImagesMutation();

  const [covers, setCovers] = useState<string[]>(initialData?.covers || []);
  const [activeFilename, setActive] = useState<string>(initialData?.covers?.[0] || '');

  const onCoverSubmit = async (selectedFileList: FileList, cafeId: string) => {
    const res = await uploadImages({ selectedFileList, id: cafeId }).unwrap();
    console.log(res);

    setCovers((prevCovers) => {
      return [...prevCovers, ...res.images];
    });

    if (res.images.length > 0) {
      setActive(res.images[res.images.length - 1]);
    }
  };
  return {
    setCovers,
    setActive,
    covers,
    activeFilename,
    onCoverSubmit
  };
};

export default useCoverSubmit;
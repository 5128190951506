import React from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
} from '@material-tailwind/react';
import { useSelector } from 'react-redux';
import {
  ComputerDesktopIcon,
  PresentationChartBarIcon,
  ListBulletIcon,
  MapPinIcon,
  PowerIcon,
  UserCircleIcon
} from '@heroicons/react/24/solid';
import { useDispatch } from 'react-redux';
import { logOut } from '../redux/slices/authSlice.ts';
import { selectCurrentUser } from '../redux/slices/authSlice.ts';

const Sidebar: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector(selectCurrentUser);

  const logout = () => {
    dispatch(logOut());
  };
  return (
    <Card className="min-h-screen w-full h-screen max-w-[15rem] p-4 shadow-xl shadow-blue-gray-900/5">
      <div className="p-4 mb-2">
        <Typography variant="h5" color="blue-gray" className='flex items-center'>
          <ComputerDesktopIcon className="w-5 h-5 mr-2" />CafeStudyMap
        </Typography>
      </div>
      <List className='grow'>
        <NavLink to="/backend/dashboard" replace={true}>
          <ListItem selected={location.pathname === '/dashboard'}>
            <ListItemPrefix>
              <PresentationChartBarIcon className="w-5 h-5" />
            </ListItemPrefix>
            看板
          </ListItem>
        </NavLink>
        <NavLink to="/backend/list" replace={true}>
          <ListItem selected={location.pathname === '/list'}>
            <ListItemPrefix>
              <ListBulletIcon className="w-5 h-5" />
            </ListItemPrefix>
            列表
          </ListItem>
        </NavLink>
        <NavLink to="/backend/map" replace={true}>
          <ListItem selected={location.pathname === '/map'}>
            <ListItemPrefix>
              <MapPinIcon className="w-5 h-5" />
            </ListItemPrefix>
            地图
          </ListItem>
        </NavLink>
        <ListItem onClick={logout}>
          <ListItemPrefix>
            <PowerIcon className="w-5 h-5" />
          </ListItemPrefix>
          登出
        </ListItem>
      </List>
      <div className='flex items-center w-full p-1 text-white bg-[#827bd4] rounded-lg'>
        <UserCircleIcon className="w-5 h-5 mr-2" />
        {user}
      </div>
    </Card>
  );
};

export default Sidebar; 